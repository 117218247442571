package dev.moetz.chatoverlay.util

import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import react.useEffect
import react.useState

fun <T> useStateFlow(flow: StateFlow<T>): T {
    val (state, setState) = useState(flow.value)

    //https://stackoverflow.com/questions/62690722/how-to-subscribe-to-stateflow-in-kotlin-react-useeffect
    useEffect() {
        val job = flow.onEach { setState(it) }.launchIn(GlobalScope)
        this.cleanup { job.cancel() }
    }

    return state
}