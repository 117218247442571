package dev.moetz.chatoverlay.page

import dev.moetz.chatoverlay.design.*
import emotion.react.css
import kotlinx.browser.window
import react.ChildrenBuilder
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.b
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h4
import react.dom.html.ReactHTML.iframe
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.ul
import react.useState
import web.cssom.*
import web.html.InputType
import web.navigator.navigator

external interface ConfigPageServiceProps : Props {
}

private fun generateOverlayLink(
    channelNamesInput: String,
    fontColorInput: String,
    fontSizeInput: Int,
    hiddenUsernamesInput: String,
    showChannelProfileImage: Boolean,
    hideCommands: Boolean,
    loadRecentMessages: Boolean,
    showPredictionBadges: Boolean,
    showSubscriberBadges: Boolean,
    showModerationBadges: Boolean,
    shadowColorInput: String,
    shadowBlurRadiusInput: Double,
    backgroundColorInput: String,
    backgroundAlphaInput: Double,
    isPreview: Boolean = false,
): String {
    val location = window.location.href

    val channels = channelNamesInput
        .split(",")
        .map { it.trim() }
        .filter { rawChannel ->
            rawChannel.isNotBlank() && rawChannel.all { it.isLetterOrDigit() || it == '_' }
        }
        .map { it.toLowerCase() }

    val hiddenUsernames = hiddenUsernamesInput
        .split(",")
        .map { it.trim() }
        .filter { rawChannel ->
            rawChannel.isNotBlank() && rawChannel.all { it.isLetterOrDigit() || it == '_' }
        }
        .map { it.toLowerCase() }

    val fontColor = fontColorInput
        .filter { it == '#' || it.isLetterOrDigit() }
        .replace("#", "%23")

    val shadowColor = shadowColorInput
        .filter { it == '#' || it.isLetterOrDigit() }
        .replace("#", "%23")

    val backgroundColor = backgroundColorInput
        .filter { it == '#' || it.isLetterOrDigit() }
        .replace("#", "%23")

    return buildString {
        append(location)
        if (location.endsWith("/").not()) {
            append("/")
        }
        append("overlay")
        append("?channels=${channels.joinToString(separator = ",")}")
        append("&fontColor=$fontColor")
        append("&fontSize=$fontSizeInput")
        append("&hiddenUsernames=${hiddenUsernames.joinToString(separator = ",")}")
        append("&showChannelProfileImage=$showChannelProfileImage")
        append("&hideCommands=${hideCommands}")
        append("&loadRecentMessages=$loadRecentMessages")
        append("&showPredictionBadges=$showPredictionBadges")
        append("&showSubscriberBadges=$showSubscriberBadges")
        append("&showModerationBadges=$showModerationBadges")
        append("&shadowColor=$shadowColor")
        append("&shadowBlurRadius=$shadowBlurRadiusInput")
        append("&backgroundColor=$backgroundColor")
        append("&backgroundAlpha=$backgroundAlphaInput")
        if (isPreview) {
            append("&preview=true")
        }
    }
}

val ConfigPageService = FC<ConfigPageServiceProps> { props ->
    var channelNamesInput by useState("")
    var fontColorInput by useState("")
    var fontSizeInput by useState(24)
    var hiddenUsernamesInput by useState("")
    var showChannelProfileImage by useState(false)
    var hideCommands by useState(false)
    var loadRecentMessages by useState(true)
    var showPredictionBadges by useState(true)
    var showSubscriberBadges by useState(true)
    var showModerationBadges by useState(true)
    var shadowColorInput by useState("")
    var shadowBlurRadiusInput by useState(5.0)
    var backgroundColorInput by useState("")
    var backgroundAlphaInput by useState(0.9)

    var darkPreviewBackground by useState(false)

    applyHeader()

    container {
        row {
            div {
                className = ClassName("col s12")
                p {
                    +"This is a free to use (multi-) twitch chat overlay service. Use the form below to create a link for your chat(s)."
                }
            }
        }

        row {

            // twitch channels
            div {
                className = ClassName("input-field col s12 m12 l12")
                input {
                    className = ClassName("white-text")
                    id = "channelNames"
                    placeholder = "Comma-separated channel names"
                    type = InputType.text
                    value = channelNamesInput
                    onChange = {
                        channelNamesInput = it.target.value
                    }
                }
                label {
                    className = ClassName("active")
                    htmlFor = "channelNames"
                    +"Twitch channel Names"
                }
            }

            // font color
            colorChooser(
                htmlId = "fontColor",
                placeholder = "Use the chooser on the right or a hex color code ('#FF00AA')",
                label = "Font color",
                value = fontColorInput,
                onChange = { fontColorInput = it }
            )

            // hidden usernames
            div {
                className = ClassName("input-field col s12 m6 l6")
                input {
                    className = ClassName("white-text")
                    id = "hiddenUsernames"
                    placeholder = "Comma-separated list of usernames to not show the messages of (e.g. bot accounts)"
                    type = InputType.text
                    value = hiddenUsernamesInput
                    onChange = {
                        hiddenUsernamesInput = it.target.value
                    }
                }
                label {
                    className = ClassName("active")
                    htmlFor = "hiddenUsernames"
                    +"Hidden usernames"
                }
            }

            // show channel profile image
            checkbox(
                falseLabel = "Hide channel profile image",
                trueLabel = "Show channel profile image",
                value = showChannelProfileImage,
                onChange = { showChannelProfileImage = it }
            )

            // font size
            numberInput(
                htmlId = "fontSize",
                label = "Font size",
                placeholderText = "Font size in px (default: 24)",
                default = 24.0,
                min = 4.0,
                max = 128.0,
                step = 1.0,
                value = fontSizeInput.toDouble(),
                onChange = {
                    fontSizeInput = it?.toInt() ?: 24
                }
            )

            // hide commands
            checkbox(
                falseLabel = "Hide commands",
                trueLabel = "Show commands",
                value = hideCommands.not(),
                onChange = { hideCommands = it.not() }
            )

            // load recent messages
            checkbox(
                falseLabel = "Do not load recent messages",
                trueLabel = "Load recent messages",
                value = loadRecentMessages,
                onChange = { loadRecentMessages = it }
            )

            // show prediction badges
            checkbox(
                falseLabel = "Do not show prediction badges",
                trueLabel = "Show prediction badges",
                value = showPredictionBadges,
                onChange = { showPredictionBadges = it }
            )

            // show subscriber badges
            checkbox(
                falseLabel = "Do not show subscriber badges",
                trueLabel = "Show subscriber badges",
                value = showSubscriberBadges,
                onChange = { showSubscriberBadges = it }
            )

            // show subscriber badges
            checkbox(
                falseLabel = "Do not show moderation badges",
                trueLabel = "Show moderation badges",
                value = showModerationBadges,
                onChange = { showModerationBadges = it }
            )

            // shadow color
            colorChooser(
                htmlId = "shadowColor",
                placeholder = "Use the chooser on the right or a hex color code ('#FF00AA')",
                label = "Shadow color",
                value = shadowColorInput,
                onChange = { shadowColorInput = it }
            )

            // shadow blur radius
            numberInput(
                htmlId = "shadowBlurRadius",
                label = "Blur radius",
                placeholderText = "Shadow blur radius",
                default = 5.0,
                min = 0.0,
                max = 15.0,
                step = 0.5,
                value = shadowBlurRadiusInput,
                onChange = {
                    if (it != null) {
                        shadowBlurRadiusInput = it
                    }
                }
            )

            // background color
            colorChooser(
                htmlId = "backgroundColor",
                placeholder = "Use the chooser on the right or a hex color code ('#FF00AA')",
                label = "Background color",
                value = backgroundColorInput,
                onChange = { backgroundColorInput = it }
            )

            // background color
            numberInput(
                htmlId = "backgroundAlpha",
                label = "Background alpha",
                placeholderText = "The alpha of the background color (between 0.0 and 1.0)",
                default = 0.9,
                min = 0.0,
                max = 1.0,
                step = 0.05,
                value = backgroundAlphaInput,
                onChange = { backgroundAlphaInput = it ?: 0.0 }
            )
        }

        row("vertical-align") {

            h4 {
                +"Link:"
            }

            div {
                className = ClassName("col s10 indigo darken-4")

                p {
                    css {
                        wordBreak = WordBreak.breakAll
                    }
                    +generateOverlayLink(
                        channelNamesInput = channelNamesInput,
                        fontColorInput = fontColorInput,
                        fontSizeInput = fontSizeInput,
                        hiddenUsernamesInput = hiddenUsernamesInput,
                        showChannelProfileImage = showChannelProfileImage,
                        hideCommands = hideCommands,
                        loadRecentMessages = loadRecentMessages,
                        showPredictionBadges = showPredictionBadges,
                        showSubscriberBadges = showSubscriberBadges,
                        showModerationBadges = showModerationBadges,
                        shadowColorInput = shadowColorInput,
                        shadowBlurRadiusInput = shadowBlurRadiusInput,
                        backgroundColorInput = backgroundColorInput,
                        backgroundAlphaInput = backgroundAlphaInput,
                    )
                }
            }

            div {
                className = ClassName("col s2")
                a {
                    className = ClassName("waves-effect waves-light btn blue")
                    +"Copy"

                    onClick = {
                        navigator.clipboard.writeText(
                            generateOverlayLink(
                                channelNamesInput = channelNamesInput,
                                fontColorInput = fontColorInput,
                                fontSizeInput = fontSizeInput,
                                hiddenUsernamesInput = hiddenUsernamesInput,
                                showChannelProfileImage = showChannelProfileImage,
                                hideCommands = hideCommands,
                                loadRecentMessages = loadRecentMessages,
                                showPredictionBadges = showPredictionBadges,
                                showSubscriberBadges = showSubscriberBadges,
                                showModerationBadges = showModerationBadges,
                                shadowColorInput = shadowColorInput,
                                shadowBlurRadiusInput = shadowBlurRadiusInput,
                                backgroundColorInput = backgroundColorInput,
                                backgroundAlphaInput = backgroundAlphaInput,
                            )
                        )
                        showSuccessToast("Copied to clipboard")
                    }
                }
            }
        }

        row {
            h4 { +"Preview:" }

            div {
                className = ClassName("col s12")

                iframe {
                    css {
                        height = 500.px
                        width = 100.pct
                        backgroundColor = if (darkPreviewBackground) {
                            Color("#000000")
                        } else {
                            Color("#FFFFFF")
                        }
                        resize = Resize.horizontal
                    }
                    src = generateOverlayLink(
                        channelNamesInput = "PreviewUser",
                        fontColorInput = fontColorInput,
                        fontSizeInput = fontSizeInput,
                        hiddenUsernamesInput = hiddenUsernamesInput,
                        showChannelProfileImage = showChannelProfileImage,
                        hideCommands = hideCommands,
                        loadRecentMessages = loadRecentMessages,
                        showPredictionBadges = showPredictionBadges,
                        showSubscriberBadges = showSubscriberBadges,
                        showModerationBadges = showModerationBadges,
                        shadowColorInput = shadowColorInput,
                        shadowBlurRadiusInput = shadowBlurRadiusInput,
                        backgroundColorInput = backgroundColorInput,
                        backgroundAlphaInput = backgroundAlphaInput,
                        isPreview = true,
                    )
                }

            }
        }

        row {
            div {
                className = ClassName("col s12 right-align")
                a {
                    className = if (darkPreviewBackground) {
                        ClassName("waves-effect waves-light btn gray")
                    } else {
                        ClassName("waves-effect waves-light btn gray disabled")
                    }
                    +"Light preview background"

                    onClick = {
                        darkPreviewBackground = false
                    }
                }
                +" "
                a {
                    className = if (darkPreviewBackground) {
                        ClassName("waves-effect waves-light btn gray disabled")
                    } else {
                        ClassName("waves-effect waves-light btn gray")
                    }
                    +"Dark preview background"

                    onClick = {
                        darkPreviewBackground = true
                    }
                }
            }
        }


        row {
            div {
                className = ClassName("col s12")

                p {
                    +"Possible query parameters:"
                    ul {
                        li {
                            b { +"channels" }
                            +": a comma-separated list of channels to display the chat messages of"
                        }
                        li {
                            b { +"fontColor" }
                            +": The color the chat messages should have, e.g. '#FF00FF' or 'black'"
                        }
                        li {
                            b { +"showChannelProfileImage" }
                            +": Boolean value whether the profile image of the chat where the message was sent in should be shown, e.g. 'true'"
                        }
                        li {
                            b { +"hiddenUsernames" }
                            +": A comma-separated list of usernames of users whose chat messages should not be shown. Useful e.g. when you don't want bot messages to be shown, provide the bot usernames here then."
                        }
                        li {
                            b { +"hideCommands" }
                            +": Whether commands (messages starting with '!') should be shown, e.g. 'false'."
                        }
                        li {
                            b { +"loadRecentMessages" }
                            +": Whether recent messages (earlier messages) should be loaded on startup, e.g. 'true'."
                        }
                        li {
                            b { +"showPredictionBadges" }
                            +": Whether prediction badges should be shown, default 'true'."
                        }
                        li {
                            b { +"showSubscriberBadges" }
                            +": Whether subscriber badges should be shown, default 'true'."
                        }
                        li {
                            b { +"showModerationBadges" }
                            +": Whether moderation badges should be shown, default 'true'."
                        }
                        li {
                            b { +"shadowColor" }
                            +": The color the text shadow should have, e.g. '#FF00FF' or 'black'"
                        }
                        li {
                            b { +"shadowBlurRadius" }
                            +": The blur radius of the text shadow, e.g. 5.0 or 2.5"
                        }
                        li {
                            b { +"backgroundColor" }
                            +": The background color of the overlay"
                        }
                        li {
                            b { +"backgroundAlpha" }
                            +": The alpha of the background color"
                        }
                    }
                }
            }
        }
    }

    applyFooter()
}


private fun ChildrenBuilder.colorChooser(
    htmlId: String,
    placeholder: String,
    label: String,
    value: String,
    onChange: (String) -> Unit,
) {
    div {
        className = ClassName("input-field col s11 m5 l5")
        input {
            this.className = ClassName("white-text")
            this.id = htmlId
            this.placeholder = placeholder
            this.type = InputType.text
            this.value = value
            this.onChange = {
                onChange.invoke(it.target.value)
            }
        }
        label {
            className = ClassName("active")
            htmlFor = htmlId
            +label
        }
    }

    div {
        className = ClassName("input-field col s1 m1 l1")

        input {
            this.className = ClassName("white-text")
            this.type = InputType.color
            this.value = value
            this.onChange = {
                onChange.invoke(it.target.value)
            }
        }
    }
}

private fun ChildrenBuilder.checkbox(
    falseLabel: String,
    trueLabel: String,
    value: Boolean,
    onChange: (Boolean) -> Unit,
) {
    // show channel profile image
    div {
        className = ClassName("col s12 m6 l6")
        br()
        div {
            className = ClassName("switch")
            label {
                +falseLabel
                input {
                    type = InputType.checkbox
                    checked = value
                    this.onChange = {
                        onChange.invoke(it.target.checked)
                    }
                }
                span {
                    className = ClassName("lever")
                }
                +trueLabel
            }
        }
        br()
    }
}

private fun ChildrenBuilder.numberInput(
    htmlId: String,
    label: String,
    placeholderText: String,
    default: Double?,
    min: Double?,
    max: Double?,
    step: Double,
    value: Double?,
    onChange: (Double?) -> Unit,
) {
    div {
        className = ClassName("input-field col s12 m6 l6")
        input {
            className = ClassName("white-text")
            id = htmlId
            placeholder = placeholderText
            type = InputType.number
            this.value = value
            defaultValue = default
            this.min = min
            this.max = max
            this.step = step
            this.onChange = {
                it.target.value.toDoubleOrNull()?.let { number ->
                    onChange.invoke(number)
                }
            }
        }
        label {
            className = ClassName("active")
            htmlFor = htmlId
            +label
        }
    }
}