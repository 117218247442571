package dev.moetz.chatoverlay

import dev.moetz.chatoverlay.page.ChatOverlayService
import dev.moetz.chatoverlay.page.ChatOverlayServiceManager
import dev.moetz.chatoverlay.page.ConfigPageService
import dev.moetz.chatoverlay.page.NotFoundPageService
import kotlinx.browser.window
import react.create
import react.dom.client.createRoot
import web.dom.document

fun main() {
    val container = document.createElement("div")
    document.body.appendChild(container)

    val path = window.location.pathname

    val element = when {
        path.isBlank() || path == "/" -> {
            ConfigPageService.create {
//                manager = ConfigPageServiceManager()
            }
        }

        path.startsWith("/overlay") || path.startsWith("overlay") -> {
            ChatOverlayService.create {
                manager = ChatOverlayServiceManager()
            }
        }

        else -> {
            NotFoundPageService.create {

            }
        }
    }

    createRoot(container).render(element)
}