package dev.moetz.chatoverlay.design

import kotlinx.browser.window
import react.ChildrenBuilder
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import web.cssom.ClassName
import web.window.WindowTarget


fun showSuccessToast(message: String) {
    showToast(
        html = message,
        classes = "green-text"
    )
}

fun showErrorToast(message: String) {
    showToast(
        html = message,
        classes = "red-text"
    )
}

fun showToast(html: String, classes: String) {
    val obj = Any().asDynamic()
    obj["html"] = html
    obj["classes"] = classes
    window.asDynamic().M.toast(obj)
}

fun ChildrenBuilder.loadingCircle() {
    br()
    br()
    div {
        className = ClassName("preloader-wrapper big active")
        div {
            className = ClassName("spinner-layer spinner-blue-only")
            div {
                className = ClassName("circle-clipper left")
                div {
                    className = ClassName("circle")
                }
            }
            div {
                className = ClassName("gap-patch")
                div {
                    className = ClassName("circle")
                }
            }
            div {
                className = ClassName("circle-clipper right")
                div {
                    className = ClassName("circle")
                }
            }
        }
    }
    br()
    br()
}

fun ChildrenBuilder.applyFooter() {
    ReactHTML.footer {
        className = ClassName("page-footer blue")
//        ReactHTML.div {
//            className = ClassName("container")
//            ReactHTML.div {
//                className = ClassName("row")
//                ReactHTML.div {
//                    className = ClassName("col l6 s12")
//                    ReactHTML.h5 {
//                        className = ClassName("white-text")
//                        +"Company Bio"
//                    }
//                    ReactHTML.p {
//                        className = ClassName("grey-text text-lighten-4")
//                        +"Some text about the service here."
//                    }
//                }
//            }
//        }

        div {
            className = ClassName("footer-copyright")
            container {
                row {
                    div {
                        className = ClassName("col l6 s6")

                        a {
                            className = ClassName("blue-text text-lighten-3")
                            href = "https://twitter.com/FlowMo_7"
                            target = WindowTarget._blank
                            +"made with "
                        }

                        span {
                            className = ClassName("red-text")
                            +"❤"
                        }

                    }


                    div {
                        className = ClassName("col l6 s6 right-align")

                        a {
                            className = ClassName("blue-text text-lighten-3")
                            href = "/"
                            +"Chatoverlay"
                        }
                        +" is "

                        a {
                            className = ClassName("blue-text text-lighten-3")
                            href = "https://gitlab.moetz.dev/twitch/chatoverlay"
                            target = WindowTarget._blank
                            +"open source here"
                        }
                    }
                }
            }
        }
    }
}

fun ChildrenBuilder.applyHeader(title: String = "Chatoverlay") {
    ReactHTML.nav {
        className = ClassName("blue darken-1")
//        role = AriaRole.navigation
        div {
            className = ClassName("nav-wrapper container")
            a {
                id = "logo-container"
                href = "/"
                className = ClassName("brand-logo")
                +title
            }
        }
    }
}

fun ChildrenBuilder.container(additionalClasses: String? = null, block: ChildrenBuilder.() -> Unit) {
    div {
        className = if (additionalClasses != null) {
            ClassName("container $additionalClasses")
        } else {
            ClassName("container")
        }

        block.invoke(this)
    }
}

fun ChildrenBuilder.row(additionalClasses: String? = null, block: ChildrenBuilder.() -> Unit) {
    div {
        className = if (additionalClasses != null) {
            ClassName("row $additionalClasses")
        } else {
            ClassName("row")
        }

        block.invoke(this)
    }
}
